import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import arbitrageLogo from "../assets/arbitrage_logo_lowercase.jpg";

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const navigate = useNavigate();

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    if (!captchaToken) {
      setError("Please complete the CAPTCHA verification");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth`,
        {
          email,
          password,
          captchaToken,
        }
      );

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        console.log("✅ Login Successful. Token saved!");

        setIsAuthenticated(true);

        navigate("/app");
      }
    } catch (err) {
      setError("Invalid email or password. Please try again.");
      console.error("❌ Login Error:", err);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <img
          style={{ width: "70px", height: "70px", marginRight: "10px" }}
          src={arbitrageLogo}
          alt="Arbitrage Logo"
          className="login-logo"
        />
        <h2>Login</h2>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Email</label>
          <input
            className="input-field"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            className="input-field"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleCaptchaChange}
          />
        </div>

        <div
          className="form-group"
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              width: "100%",
              padding: "12px 0",
              backgroundColor: "#4285F4",
              color: "white",
              border: "none",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
              transition: "all 0.3s ease",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
            type="submit"
            className="auth-button"
            disabled={!captchaToken}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#3367D6")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#4285F4")}
          >
            Login
          </button>
        </div>
      </form>
      <p>If you can't login, please contact the admins.</p>
    </div>
  );
};

export default Login;
