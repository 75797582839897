import axios from "axios";
import { getEndpoint, getApiType } from "../static/config";

/**
 * Makes a POST request to the API
 * @param {string} provider - The API provider (e.g., "SOCGEN", "BNP")
 * @param {string} type - The API endpoint type (e.g., "QUOTES")
 * @param {object} payload - The data to send in the request
 * @returns {Promise} - The response data from the API
 */
export const fetchFromAPIPost = async (provider, type, payload) => {
  try {
    const endpoint = getEndpoint(provider, type);
    const apiType = getApiType(provider, type);

    const requestPayload = {
      ...payload,
      apiType,
    };
    const token = localStorage.getItem("token");
    const response = await axios.post(endpoint, requestPayload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      `Error in fetchFromAPIPost (${provider}.${type}):`,
      error.message
    );
    throw error;
  }
};

/**
 * Makes a GET request to the API
 * @param {string} provider - The API provider (e.g., "SOCGEN", "BNP")
 * @param {string} type - The API endpoint type (e.g., "GET_QUOTE")
 * @param {object} params - The parameters to send in the request
 * @returns {Promise} - The response data from the API
 */
export const fetchFromAPIGet = async (provider, type, params = {}) => {
  try {
    const endpoint = getEndpoint(provider, type);
    const apiType = getApiType(provider, type);
    const requestParams = {
      ...params,
      apiType,
    };
    const token = localStorage.getItem("token");
    const response = await axios.get(endpoint, {
      params: requestParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      `Error in fetchFromAPIGet (${provider}.${type}):`,
      error.message
    );
    throw error;
  }
};

export const fetchQuoteDetails = async (provider, type, payload) => {
  try {
    const endpoint = getEndpoint(provider, type);
    const apiType = getApiType(provider, type);

    const requestPayload = {
      ...payload,
      apiType,
    };
    const token = localStorage.getItem("token");
    const response = await axios.post(endpoint, requestPayload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      `Error in fetchQuoteDetails (${provider}.${type}):`,
      error.message
    );
    throw error;
  }
};
