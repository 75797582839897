export const API_ENDPOINTS = {
  SOCGEN: {
    QUOTES: "http://localhost:3001/quotes",
    GET_QUOTE: "http://localhost:3001/getQuote",
    UNDERLYINGS: "http://localhost:3001/underlying",
  },
};

export const API_TYPES = {
  SOCGEN: {
    QUOTES: "socgenQuotesURL",
    GET_QUOTE: "socgenGetQuotesURL",
    UNDERLYINGS: "socgenUnderlyingsURL",
  },
};

export const getEndpoint = (provider, type) => {
  if (!API_ENDPOINTS[provider] || !API_ENDPOINTS[provider][type]) {
    throw new Error(`Invalid endpoint: ${provider}.${type}`);
  }
  return API_ENDPOINTS[provider][type];
};
export const getApiType = (provider, type) => {
  if (!API_TYPES[provider] || !API_TYPES[provider][type]) {
    throw new Error(`Invalid API type: ${provider}.${type}`);
  }
  return API_TYPES[provider][type];
};

export const ENVIRONMENT = {
  CURRENT: process.env.NODE_ENV || "development",
  BASE_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001",
};
