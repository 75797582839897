import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  //   faSearch,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";
import arbitrageLogo from "../../assets/arbitrage_logo_lowercase.jpg";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <div className="brand">
          {/* <img src="/logo.png" alt="Brand Logo" className="brand-logo" /> */}
          <img
            src={arbitrageLogo}
            alt="Arbitrage Logo"
            className="brand-logo"
          />
          <span className="brand-name">Arbitrage</span>
        </div>
      </div>

      <div className="navbar-right">
        <FontAwesomeIcon icon={faBell} className="icon" />
        <FontAwesomeIcon icon={faCog} className="icon" />
        <div className="profile">
          <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
